<template>
    
    <b-card class="outer-card">
        <b-card class="inner-card">
            <div class="row" v-if="validLink == 1">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Ponastavite geslo
                </div>
                <div class="col-12 text-center mt-2">
                    Spodaj vnesite novo geslo za vaš račun.
                </div>
                <div class="col-12 mt-2">
                    <validation-observer ref="resetPasswordValidator">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-4 col-md-6 col-11">
                                <b-form-group label="Geslo">
                                    <validation-provider #default="{ errors }" name="geslo" rules="required">
                                        <b-form-input v-model="password" type="password" name="login-password" placeholder="geslo" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-4 col-md-6 col-11">
                                <b-form-group label="Ponovno vnesite geslo">
                                    <validation-provider #default="{ errors }" name="Geslo ponovitev" rules="required|confirmed:geslo">
                                        <b-form-input v-model="passwordConfirm" type="password" name="login-password" placeholder="geslo" data-vv-as="geslo" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                        </div>
                    </validation-observer>
                </div>
                <div class="col-12 mt-2">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-4 col-md-6 col-11 d-flex justify-content-center">
                            <b-button @click="resetPassowrd()">Ponastavi geslo</b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="validLink == 2">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Povezava za ponastavitev je žal potekla!
                </div>
            </div>
        </b-card>
    </b-card>

</template>

<script>
    import { BCard, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, confirmed } from '@validations'
    export default {
        components:{
            BCard,
            BFormGroup,
            BFormInput,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                password: null,
                validLink: null,
                required,
                confirmed,
                passwordConfirm: null

            }
        },
        methods: {
            async resetPassowrd() {
                try {

                    const data = {
                        password: this.password,
                        reset_password_id: this.$route.params.password_id
                    }

                    await this.$http.post('/api/user/v1/reset_password', data)
                    this.$printSuccess('Vaše geslo je bilo uspešno ponastavljeno!')  
                    this.$router.push('/prijava')
                } catch (err) {
                    this.$printError('Prišlo je do napake prosim poskusite ponovno!')  
                }

            }
        },
        async mounted() {
            try {
                await this.$http.get(`/api/user/v1/check_reset_password?linkId=${ this.$route.params.password_id}`)

                this.validLink = 1
            } catch (err) {
                this.validLink = 2
            }
        }
    }
</script>


<style scoped>
    .outer-card{
        box-shadow: none !important;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(180deg, rgba(234,243,249,1) 0%, rgba(234,243,249,1) 85%, rgba(255,255,255,1) 95%);
        min-height: 60vh !important;
    }

    .inner-card{
        box-shadow: none !important;
        margin-left: 2000px !important;
        margin-right: 2000px !important;
        border-radius: 10px !important;
    }

</style>